/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { ethers } from 'ethers';
import { Link, PageProps } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { StitchedPunksShop, StitchedPunksShop__factory } from '../../contracts/artifacts/types';
import {
    createTxViewerUrl,
    formatPrice,
    ICON_EMPTY_COLORED_FILENAME,
    ICON_EMPTY_FILENAME,
    orderStatusToString,
    STITCHEDPUNKSSHOP_CONTRACT,
    truncAddress,
    validateEmail,
} from '../web3/BlockchainUtils';
import { OrderStatus, StitchedPunkMetadata } from '../web3/dtos';
import Layout from './Layout';

// types for GraphQL result
interface QueryEdge {
    node: {
        gatsbyImageData: IGatsbyImageData;
        fluid: {
            originalName: string;
            originalImg: string;
        };
    };
}

interface AllImagesData {
    imgIcon: { edges: QueryEdge[] };
    imgGallery: { edges: QueryEdge[] };
}

interface TemplateDetailsProps extends PageProps {
    cryptopunk: {
        id: string;
        imgUrl: string;
        metadata?: StitchedPunkMetadata;
    };
    allImages: AllImagesData;
}

const OrderStatusDisplay = ({ orderStatus }: { orderStatus: string }): JSX.Element => {
    if (orderStatus === OrderStatus.NotCreated) {
        return (
            <>
                Status: <i>{orderStatusToString(orderStatus)}</i>
            </>
        );
    }

    const isOrderStatus10 = orderStatus === OrderStatus.CreatedAndPaid;
    const isOrderStatus20 = orderStatus === OrderStatus.Crafting;
    const isOrderStatus30 = orderStatus === OrderStatus.Shipped;
    const isOrderStatus40 = orderStatus === OrderStatus.ReceivedAndNFTRedeemed;

    return (
        <ul className="alt order-status-list">
            <li>Status:</li>
            <li className={'status icon fa-shopping-cart' + (isOrderStatus10 ? ' status-active' : '')}>
                {orderStatusToString(OrderStatus.CreatedAndPaid)}
            </li>
            <li className={'status icon fa-cogs' + (isOrderStatus20 ? ' status-active' : '')}>
                {orderStatusToString(OrderStatus.Crafting)}
            </li>
            <li className={'status icon fa-truck' + (isOrderStatus30 ? ' status-active' : '')}>
                {orderStatusToString(OrderStatus.Shipped)}
            </li>
            <li className={'status icon fa-magic' + (isOrderStatus40 ? ' status-active' : '')}>
                {orderStatusToString(OrderStatus.ReceivedAndNFTRedeemed)}
            </li>
        </ul>
    );
};

const TemplateDetails = ({ pageContext }: { pageContext: TemplateDetailsProps }): JSX.Element => {
    // fetch metadata from page params
    // console.log('pageContext: ', pageContext);
    const allImages = pageContext.allImages;
    // console.log('allImages: ', allImages);
    const cryptopunk = pageContext.cryptopunk;
    const punkId = cryptopunk.id;

    const [isErrorMetaMask, setIsErrorMetaMask] = useState(false);

    const [isLoadingOwnership, setIsLoadingOwnership] = useState(true);
    const [isLoadingOrderStatus, setIsLoadingOrderStatus] = useState(true);
    const [isLoadingCurrentPrice, setIsLoadingCurrentPrice] = useState(true);
    const [isLoadingSendingOrder, setIsLoadingSendingOrder] = useState(false);

    // generate image URL
    const paddedId = punkId.toString().padStart(4, '0');
    cryptopunk.imgUrl = '/cryptopunks/punk' + paddedId + '.png';

    // create icon and gallery (if exists)
    const imageIcon: JSX.Element[] = [];
    const imageIconColored: JSX.Element[] = [];
    const imagesGallery: JSX.Element[] = [];
    const isMetadataExisting = cryptopunk.metadata !== undefined && cryptopunk.metadata !== null;

    if (isMetadataExisting) {
        const imgIconEdges = allImages.imgIcon.edges;
        imgIconEdges.forEach((edge: QueryEdge) => {
            if (edge.node.fluid.originalName === cryptopunk.metadata?.imgIcon) {
                imageIcon.push(
                    <a href={edge.node.fluid.originalImg} key={edge.node.fluid.originalName}>
                        <GatsbyImage
                            image={edge.node.gatsbyImageData}
                            alt="Photo of StitchedPunk"
                            className="inverted-hover"
                        />
                    </a>,
                );
            }
        });

        const imgGalleryEdges = allImages.imgGallery.edges;
        imgGalleryEdges.sort((a: QueryEdge, b: QueryEdge) =>
            a.node.fluid.originalName.toLowerCase().localeCompare(b.node.fluid.originalName.toLowerCase()),
        );
        imgGalleryEdges.forEach((edge: QueryEdge) => {
            if (cryptopunk.metadata?.imgGallery.indexOf(edge.node.fluid.originalName) !== -1) {
                imagesGallery.push(
                    <div className="col-4" key={edge.node.fluid.originalName}>
                        <span className="image fit">
                            <a href={edge.node.fluid.originalImg}>
                                <GatsbyImage
                                    image={edge.node.gatsbyImageData}
                                    alt="Photo of StitchedPunk"
                                    className="inverted-hover"
                                />
                            </a>
                        </span>
                    </div>,
                );
            }
        });
    } else {
        // no metadata given: show placeholder image as icon
        const imgIconEdges = allImages.imgIcon.edges;
        imgIconEdges.forEach((edge: QueryEdge) => {
            if (edge.node.fluid.originalName === ICON_EMPTY_FILENAME) {
                imageIcon.push(
                    <GatsbyImage
                        image={edge.node.gatsbyImageData}
                        alt="No StitchedPunk created yet"
                        key={edge.node.fluid.originalName}
                    />,
                );
            }
        });
        imgIconEdges.forEach((edge: QueryEdge) => {
            if (edge.node.fluid.originalName === ICON_EMPTY_COLORED_FILENAME) {
                imageIconColored.push(
                    <GatsbyImage
                        image={edge.node.gatsbyImageData}
                        alt="StitchedPunk created but no photo yet"
                        key={edge.node.fluid.originalName}
                    />,
                );
            }
        });
    }

    // fetch punk ownership
    const web3 = useWeb3React();
    const [walletPunkOwner, setWalletPunkOwner] = useState('Loading...');
    const [isOwnedAsWrappedPunk, setIsOwnedAsWrappedPunk] = useState(false);

    const checkOwnership = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            setIsLoadingOwnership(false);
            setIsErrorMetaMask(true);
            return;
        }

        setIsLoadingOwnership(true);
        setIsErrorMetaMask(false);

        try {
            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(
                STITCHEDPUNKSSHOP_CONTRACT,
                web3.library,
            );
            const ownerOfCPValue = await shop.getOwnerForCryptoPunk(punkId);
            const ownerOfCP = ownerOfCPValue.toLowerCase();
            console.log('ownerOfCP:', ownerOfCP);
            const ownerOfWPValue = await shop.getOwnerForWrappedPunk(punkId);
            const ownerOfWP = ownerOfWPValue.toLowerCase();
            console.log('ownerOfWP:', ownerOfWP);
            setWalletPunkOwner(ownerOfWP === ethers.constants.AddressZero ? ownerOfCP : ownerOfWP);
            setIsOwnedAsWrappedPunk(ownerOfWP !== ethers.constants.AddressZero);

            setIsLoadingOwnership(false);
        } catch (err) {
            setIsLoadingOwnership(false);
            setIsErrorMetaMask(true);
            console.log('error checkOwnership:', err);
        }
    }, [punkId, web3.active, web3.library]);

    useEffect(() => {
        checkOwnership();
    }, [checkOwnership]);

    // fetch order status
    const [orderStatus, setOrderStatus] = useState('Loading...');

    const fetchOrderStatus = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            setIsLoadingOrderStatus(false);
            setIsErrorMetaMask(true);
            return;
        }

        setIsLoadingOrderStatus(true);
        setIsErrorMetaMask(false);

        try {
            const signer = web3.library.getSigner();

            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(STITCHEDPUNKSSHOP_CONTRACT, signer);

            const currentOrderStatus = await shop.orderStatus(punkId);
            console.log('currentOrderStatus #' + punkId + ':', currentOrderStatus);
            setOrderStatus(currentOrderStatus.status.toString());

            if (currentOrderStatus.status.toString() === OrderStatus.CreatedAndPaid) {
                setIsOrderSubmitted(true);
            }
            setIsLoadingOrderStatus(false);
        } catch (err) {
            setIsLoadingOrderStatus(false);
            setIsErrorMetaMask(true);
            console.log('error fetchOrderStatus:', err);
        }
    }, [punkId, web3.active, web3.library]);

    useEffect(() => {
        fetchOrderStatus();
    }, [fetchOrderStatus]);

    const [orderEmail, setOrderEmail] = useState('');

    const [currentOrderPrice, setCurrentOrderPrice] = useState('Loading...');

    const fetchCurrentOrderPrice = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            setIsLoadingCurrentPrice(false);
            setIsErrorMetaMask(true);
            return;
        }

        setIsLoadingCurrentPrice(true);
        setIsErrorMetaMask(false);

        try {
            const signer = web3.library.getSigner();

            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(STITCHEDPUNKSSHOP_CONTRACT, signer);

            // fetch current price
            const currentPrice = await shop.currentOrderPrice();
            console.log('currentPrice:', currentPrice);

            // format price
            setCurrentOrderPrice(formatPrice(ethers.utils.formatUnits(currentPrice, 'ether')) + ' ETH');

            setIsLoadingCurrentPrice(false);
        } catch (err) {
            setIsLoadingCurrentPrice(false);
            setIsErrorMetaMask(true);
            console.log('error fetchCurrentOrderPrice:', err);
        }
    }, [web3.active, web3.library]);

    useEffect(() => {
        fetchCurrentOrderPrice();
    }, [fetchCurrentOrderPrice]);

    const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);
    const [isOrderError, setIsOrderError] = useState(false);
    const [isFundingError, setIsFundingError] = useState(false);
    const [orderTxHash, setOrderTxHash] = useState('');
    const [isOrderTxDone, setIsOrderTxDone] = useState(false);

    const orderStitchedPunk = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            setIsLoadingSendingOrder(false);
            setIsErrorMetaMask(true);
            return;
        }

        if (isOrderSubmitted) {
            return;
        }

        setIsLoadingSendingOrder(true);
        setIsOrderError(false);
        setIsFundingError(false);
        setIsErrorMetaMask(false);

        try {
            const signer = web3.library.getSigner();

            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(STITCHEDPUNKSSHOP_CONTRACT, signer);

            // fetch current order status
            const currentOrderStatus = await shop.orderStatus(punkId);
            console.log('currentOrderStatus #' + punkId + ':', currentOrderStatus);
            setOrderStatus(currentOrderStatus.status.toString());

            // fetch current price
            const currentPrice = await shop.currentOrderPrice();
            console.log('currentPrice:', currentPrice);

            // send order
            const txOrder = await shop.submitOrder(punkId, { value: currentPrice });
            setIsOrderSubmitted(true);
            setOrderTxHash(txOrder.hash);
            console.log('order submitted, waiting for TX...');

            const txReceipt = await txOrder.wait();
            console.log('TX done in block' + txReceipt.blockNumber);
            setIsOrderTxDone(true);

            const updatedOrderStatus = await shop.orderStatus(punkId);
            console.log('updatedOrderStatus #' + punkId + ':', updatedOrderStatus);
            setOrderStatus(updatedOrderStatus.status.toString());

            // save order to database (GET params: "punkid", "wallet", "txid" and "email")
            console.log('saving order to database...');
            const response = await axios.get('/submit-order.php', {
                params: { punkid: punkId, wallet: walletPunkOwner, txid: txOrder.hash, email: orderEmail },
            });
            console.log('saving order to database done!', response.data);

            setIsLoadingSendingOrder(false);
        } catch (err) {
            console.log('error ordering:', err);

            // show special error message if not enough ether is in the wallet
            if (err.message.indexOf('insufficient funds') !== -1) {
                console.log('insufficient funds!');
                setIsFundingError(true);
            }

            setIsOrderSubmitted(false);
            setIsLoadingSendingOrder(false);
            setIsOrderError(true);
        }
    }, [isOrderSubmitted, orderEmail, punkId, walletPunkOwner, web3.active, web3.library]);

    const isCurrentWalletOwner = walletPunkOwner === web3.account?.toLowerCase();
    const isEmailValid = validateEmail(orderEmail);
    const isOrderReadyToSubmit = isCurrentWalletOwner && isEmailValid;
    const isOrderCreatedAndPaid = orderStatus !== 'Loading...' && orderStatus !== OrderStatus.NotCreated;
    const isLoadingAnything =
        isLoadingOwnership || isLoadingOrderStatus || isLoadingCurrentPrice || isLoadingSendingOrder;

    return (
        <Layout>
            <Helmet
                title={'StitchedPunks – #' + cryptopunk.id + ' Details'}
                meta={[{ name: 'description', content: 'StitchedPunks – Details for StitchedPunk #' + cryptopunk.id }]}
            />

            <div id="templatedetails" className="alt">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h1>StitchedPunk {cryptopunk.id}</h1>
                        </header>
                        <div className="box alt">
                            <div className="grid-wrapper">
                                <div className="col-9">
                                    <p>
                                        Order your own physical StitchedPunk by following the steps below. Each one is
                                        created as a unique 1/1 edition and can only be ordered once by the owner of the
                                        corresponding CryptoPunk.{' '}
                                        <i>
                                            <Link to="/">More about StitchedPunks.</Link>
                                        </i>
                                    </p>
                                    <p>
                                        The current price is{' '}
                                        {currentOrderPrice === 'Loading...' ? '?' : currentOrderPrice}, which can change
                                        in the future based on demand. So go ahead and be one of the first in line!{' '}
                                    </p>
                                </div>
                                <div className="col-3">
                                    <span className="image fit">
                                        <Link to="/gallery">
                                            <StaticImage
                                                src="../assets/images/stitchedpunk-example-triple.jpg"
                                                alt="Three StitchedPunk examples"
                                                className="inverted-hover"
                                            />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="grid-wrapper punk-card">
                            {isLoadingAnything && (
                                <div className="col-12 message-box">
                                    <span className="loading-animation">Loading...</span>
                                </div>
                            )}
                            {isErrorMetaMask && !isLoadingAnything && (
                                <div className="col-12 message-box">
                                    <span className="error-message">
                                        <span role="img" aria-label="exclamation-mark icon">
                                            ⚠️
                                        </span>{' '}
                                        Could not connect to Ethereum. Please reconnect in the menu, check your MetaMask
                                        and try again.
                                    </span>
                                </div>
                            )}
                            <div className="col-6 grid-wrapper">
                                <div className="col-12">
                                    <h4>CryptoPunk</h4>
                                </div>
                                <div className="col-4">
                                    <img
                                        src={cryptopunk.imgUrl}
                                        alt={'CryptoPunk #' + cryptopunk.id}
                                        className="punk-image pixelated"
                                    />
                                </div>
                                <div className="col-8">
                                    <ul className="alt">
                                        <li>
                                            Current Owner: {(walletPunkOwner === '?' || isErrorMetaMask) && '?'}
                                            {walletPunkOwner !== '?' && !isErrorMetaMask && (
                                                <Link to={'/profile/' + walletPunkOwner}>
                                                    {truncAddress(walletPunkOwner)}
                                                    {isCurrentWalletOwner ? <i> (You)</i> : ''}
                                                </Link>
                                            )}
                                        </li>
                                        <li>
                                            <a
                                                href={'https://www.larvalabs.com/cryptopunks/details/' + cryptopunk.id}
                                                className="icon fa-external-link">
                                                Show on CryptoPunks Website
                                            </a>
                                        </li>
                                        {isOwnedAsWrappedPunk && <li>Owned as WrappedPunk</li>}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 grid-wrapper">
                                <div className="col-12">
                                    <h4>StitchedPunk</h4>
                                </div>
                                <div className="col-4">
                                    {isMetadataExisting && imageIcon}
                                    {!isMetadataExisting &&
                                        (orderStatus === OrderStatus.NotCreated || orderStatus === 'Loading...'
                                            ? imageIcon
                                            : imageIconColored)}
                                </div>
                                <div className="col-8">
                                    {!isLoadingOrderStatus &&
                                        (!isOrderCreatedAndPaid || (isOrderCreatedAndPaid && isOrderTxDone)) && (
                                            <ul className="alt order-steps">
                                                {!isLoadingOwnership && !isCurrentWalletOwner && !isErrorMetaMask && (
                                                    <li>
                                                        <i>You need to own this CryptoPunk to submit an order.</i>
                                                    </li>
                                                )}
                                                <li className={!isCurrentWalletOwner ? 'deactivated' : ''}>
                                                    <OrderStatusDisplay orderStatus={orderStatus} />
                                                </li>
                                                <li className={!isCurrentWalletOwner ? ' deactivated' : ''}>
                                                    <span
                                                        className={
                                                            'icon' +
                                                            (isCurrentWalletOwner
                                                                ? ' fa-check-circle'
                                                                : ' fa-circle-thin')
                                                        }>
                                                        Prove Ownership
                                                    </span>
                                                    <br />
                                                    <span className="explanation">
                                                        Your ownership will be checked again in the smart contract when
                                                        submitting the order.
                                                    </span>
                                                </li>
                                                <li className={!isCurrentWalletOwner ? ' deactivated' : ''}>
                                                    <span
                                                        className={
                                                            'icon' +
                                                            (isEmailValid ? ' fa-check-circle' : ' fa-circle-thin')
                                                        }>
                                                        Enter Email:
                                                    </span>
                                                    <input
                                                        type="text"
                                                        name="order-email"
                                                        id="order-email"
                                                        className={
                                                            orderEmail !== '' && !isEmailValid
                                                                ? 'order-email-invalid'
                                                                : ''
                                                        }
                                                        disabled={!isCurrentWalletOwner}
                                                        defaultValue=""
                                                        placeholder=""
                                                        onChange={(e) => setOrderEmail(e.target.value)}
                                                    />
                                                    <span className="explanation">
                                                        We use your email to contact you before shipping and send status
                                                        updates on your order.
                                                    </span>
                                                </li>
                                                <li className={!isOrderReadyToSubmit ? ' deactivated' : ''}>
                                                    <span
                                                        className={
                                                            'icon' +
                                                            (isOrderReadyToSubmit
                                                                ? ' fa-check-circle'
                                                                : ' fa-circle-thin')
                                                        }>
                                                        Submit Order
                                                    </span>
                                                    <br />
                                                    <span className="explanation">
                                                        You are now ready to submit your order! Worldwide shipping is
                                                        included in the current price of {currentOrderPrice}.
                                                    </span>
                                                    <br />
                                                    <a
                                                        onClick={() => orderStitchedPunk()}
                                                        role="button"
                                                        className={
                                                            'button small icon fa-shopping-cart' +
                                                            (!isOrderReadyToSubmit ? ' disabled' : '')
                                                        }>
                                                        Order StitchedPunk
                                                    </a>
                                                </li>
                                                {isOrderError && !isFundingError && (
                                                    <li className="explanation error-message">
                                                        <span role="img" aria-label="exclamation-mark icon">
                                                            ⚠️
                                                        </span>{' '}
                                                        Error while sending your order. Please try again.
                                                    </li>
                                                )}
                                                {isFundingError && (
                                                    <li className="explanation error-message">
                                                        <span role="img" aria-label="exclamation-mark icon">
                                                            ⚠️
                                                        </span>{' '}
                                                        Your wallet contains not enough Ether. Make sure to cover the
                                                        current price and gas cost.
                                                    </li>
                                                )}
                                                {isOrderSubmitted && !isOrderTxDone && (
                                                    <li className="loading-message">
                                                        <span className="loading-animation">Loading...</span>
                                                    </li>
                                                )}
                                                {isOrderSubmitted && (
                                                    <li>
                                                        <span
                                                            className={
                                                                'icon' +
                                                                (isOrderTxDone ? ' fa-check-circle' : ' fa-circle-thin')
                                                            }>
                                                            Saving Order
                                                        </span>
                                                        <br />
                                                        <span className="explanation">
                                                            Your order was submitted.
                                                            {orderTxHash !== '' && (
                                                                <>
                                                                    <br />
                                                                    <a
                                                                        href={createTxViewerUrl(orderTxHash)}
                                                                        className="icon fa-external-link">
                                                                        Show transaction on Etherscan
                                                                    </a>
                                                                </>
                                                            )}
                                                            {!isOrderTxDone && (
                                                                <>
                                                                    <br />
                                                                    Waiting for the transaction to be confirmed...
                                                                </>
                                                            )}
                                                        </span>
                                                    </li>
                                                )}
                                                {isOrderTxDone && (
                                                    <li>
                                                        <span className="icon fa-check-circle">Order Successful</span>
                                                        {isOrderSubmitted && (
                                                            <>
                                                                <br />
                                                                <span className="explanation">
                                                                    Congratulations! Your order was successful! We will
                                                                    start creating your StitchedPunk now and notify you
                                                                    on any updates via email.
                                                                </span>
                                                            </>
                                                        )}
                                                    </li>
                                                )}
                                            </ul>
                                        )}
                                    {isOrderCreatedAndPaid && !isOrderTxDone && (
                                        <ul className="alt order-steps">
                                            <li>
                                                <OrderStatusDisplay orderStatus={orderStatus} />
                                            </li>
                                            {!isErrorMetaMask && (
                                                <li>
                                                    <span className="icon fa-check-circle">Order Successful</span>
                                                    <br />
                                                    <span className="explanation">
                                                        Congratulations! Your order was successful! We will start
                                                        creating your StitchedPunk now and notify you on any updates via
                                                        email.
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>

                        {imagesGallery.length > 0 && (
                            <div>
                                <h2>Gallery</h2>
                                <div className="box alt">
                                    <div className="grid-wrapper">{imagesGallery}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default TemplateDetails;
