export enum OrderStatus {
    NotCreated = '0',
    CreatedAndPaid = '10',
    Crafting = '20',
    Shipped = '30',
    ReceivedAndNFTRedeemed = '40',
    Unknown = '255',
}
export interface StitchedPunkMetadata {
    id: string;
    productionNumber?: string;
    productionDate?: string;
    physicalLocation?: string;
    nftClaimed: boolean;
    orderStatus: number;
    imgIcon: string;
    imgGallery: string[];
    materials: string[];
}
